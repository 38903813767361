<template>
  <div>
    <v-container
      :style="`width: ${
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? ''
          : '1200px !important'
      };`"
    >
      <v-row justify="center">
        <v-col cols="12" md="12">
          <v-card tile>
            <v-card-text>
              <v-toolbar flat dark color="primary">
                <v-btn icon dark @click="callbackProductList">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{
                  $t("productOrder.detail.order") +
                  " " +
                  orderCode +
                  " " +
                  $t("productOrder.detail.detail")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="productHeaders"
                :items="productsList"
                class="mt-6 elevation-1"
                hide-default-footer
                :mobile-breakpoint="0"
              >
                <template
                  v-for="h in productHeaders"
                  v-slot:[`header.${h.value}`]="{ header }"
                >
                  {{ $t(header.text) }}
                </template>
                <template v-slot:[`item.image`]="item">
                  <img
                    :src="item.item.image"
                    class="pt-2"
                    width="100px"
                    height="70px"
                  />
                </template>
                <template v-slot:[`item.quantity`]="item">
                  {{ "x" + item.item.quantity }}
                </template>
                <template v-slot:[`item.price`]="item">
                  {{ formatMoney(item.item.price) + " VND" }}
                </template>
                <template v-slot:[`item.totalPrice`]="item">
                  {{ formatMoney(item.item.totalPrice) + " VND" }}
                </template>
              </v-data-table>
              <div
                v-if="
                  orderPaymentConfirmStatus ===
                  baoKimOrderConfirmStatus.COMPLETED
                "
                class="text-center font-large pa-2"
                style="color: blue"
              >
                {{ $t("baoKimConfig.paymentConfirm.completed") }}
              </div>
              <div
                v-else
                class="text-center font-large pa-2"
                style="color: red"
              >
                {{ $t("baoKimConfig.paymentConfirm.errorPaymentConfirm") }}
              </div>
              <v-btn color="warning" @click="callbackProductList">{{ $t("common.back") }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import dateUtils from "Utils/dateUtils";
import functionUtils from "Utils/functionUtils";
import baoKimOrderConfirmStatus from "Enum/BaoKimOrderConfirmStatus";
export default {
  components: {},
  data: () => ({
    productHeaders: [
      { text: "#", value: "stt" },
      // { text: "productOrder.tableList.header.orderCode", value: "orderCode" },
      { text: "productOrder.detail.image", value: "image" },
      { text: "productOrder.tableList.header.product", value: "product" },
      {
        text: "productOrder.detail.price",
        value: "price",
      },
      { text: "productOrder.detail.quantity", value: "quantity" },
      { text: "productOrder.tableList.header.totalPrice", value: "totalPrice" },
    ],
    productsList: [],
    orderCode: null,
    orderPaymentConfirmStatus: null,
    filterData: {
      code: null,
    },
    baoKimOrderConfirmStatus: baoKimOrderConfirmStatus,
  }),
  created() {
    let query = this.$route.query;
    this.filterData.code = query.mrc_order_id;
    this.getProductOrderDetail();
    //
    this.orderPaymentConfirmStatus = query.stat;
    if (this.orderPaymentConfirmStatus === baoKimOrderConfirmStatus.COMPLETED) {
      this.approveOnlineOrderPayment()
    }
  },
  methods: {
    getProductOrderDetail: function () {
      let filter = {
        params: {
          code: this.filterData.code,
        },
      };
      this.GET_PRODUCT_ORDER_DETAIL(filter).then(
        function (res) {
          let data = res.data.length > 0 ? res.data[0] : null;
          this.productsList = [];
          if (data) {
            this.orderCode = data.code;
            let productsList = data.items;
            productsList.forEach((e, i) => {
              let obj = {
                stt: i + 1,
                product: e.workpiece.name,
                price: e.workpiece.value,
                quantity: e.quantity,
                totalPrice: e.workpiece.value * e.quantity,
                image: e.workpiece.image_url,
              };
              this.productsList.push(obj);
            });
          }
        }.bind(this)
      );
    },
    /**
     * Get total price
     */
    getTotalPrice: function (items) {
      let total = 0;
      for (let i = 0, size = items.length; i < size; i++) {
        total += items[i].workpiece.value * items[i].quantity;
      }
      return functionUtils.convertFormatNumberToMoney(total.toString());
    },
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString());
    },
    approveOnlineOrderPayment: function () {
      let data = {
        productOrderCode: this.filterData.code
      }
      this.APPROVE_BAO_KIM_ORDER_PAYMENT(data).then(
        function () {
        }.bind(this)
      );
    },
    callbackProductList: function () {
      this.$router.push({
        name: 'Home'
      })
    },
    ...mapActions(["GET_PRODUCT_ORDER_DETAIL", "APPROVE_BAO_KIM_ORDER_PAYMENT"]),
  },
};
</script>
<style scoped>
.v-card {
  width: 100%;
}
</style>
