var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{style:(("width: " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
        ? ''
        : '1200px !important') + ";"))},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.callbackProductList}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("productOrder.detail.order") + " " + _vm.orderCode + " " + _vm.$t("productOrder.detail.detail")))])],1),_c('v-data-table',{staticClass:"mt-6 elevation-1",attrs:{"headers":_vm.productHeaders,"items":_vm.productsList,"hide-default-footer":"","mobile-breakpoint":0},scopedSlots:_vm._u([_vm._l((_vm.productHeaders),function(h){return {key:("header." + (h.value)),fn:function(ref){
    var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}}),{key:"item.image",fn:function(item){return [_c('img',{staticClass:"pt-2",attrs:{"src":item.item.image,"width":"100px","height":"70px"}})]}},{key:"item.quantity",fn:function(item){return [_vm._v(" "+_vm._s("x" + item.item.quantity)+" ")]}},{key:"item.price",fn:function(item){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.item.price) + " VND")+" ")]}},{key:"item.totalPrice",fn:function(item){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.item.totalPrice) + " VND")+" ")]}}],null,true)}),(
                _vm.orderPaymentConfirmStatus ===
                _vm.baoKimOrderConfirmStatus.COMPLETED
              )?_c('div',{staticClass:"text-center font-large pa-2",staticStyle:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.$t("baoKimConfig.paymentConfirm.completed"))+" ")]):_c('div',{staticClass:"text-center font-large pa-2",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("baoKimConfig.paymentConfirm.errorPaymentConfirm"))+" ")]),_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.callbackProductList}},[_vm._v(_vm._s(_vm.$t("common.back")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }